import { CopyOutlined } from '@ant-design/icons';
import { Button, Popover, Tag, Tooltip } from 'antd';
import React from 'react';
import { PackageLine } from '../../../../common/models';
import { Utils } from '../../../../common/services/Utils';
import { addNewLines } from '../Utils';
import { observer } from 'mobx-react-lite';

type TagChildNodeTitleProps = {
    field: PackageLine;
    tagValJsonObject: JSX.Element | undefined;
    value: string;
    index: number;
};

const TagChildNodeTitle: React.FC<TagChildNodeTitleProps> = ({ field, tagValJsonObject, value, index }) => {
    return (
        <>
            <Tooltip title="Copy value to clipboard">
                <Button
                    style={{ height: 24 }}
                    icon={<CopyOutlined style={{ fontSize: 12, verticalAlign: 'baseline' }} />}
                    type="link"
                    onClick={() => Utils.copyValToClipboard(value)}
                />
            </Tooltip>
            <Popover
                content={
                    <div style={{ maxWidth: 500, maxHeight: 400, overflow: 'auto' }}>
                        {tagValJsonObject != null ? tagValJsonObject : value}
                    </div>
                }
                autoAdjustOverflow
                placement="bottomLeft"
            >
                <Tag key={`${field.rowId}-${value}-${index}`}>{addNewLines(value)}</Tag>
            </Popover>
        </>
    );
};

export default observer(TagChildNodeTitle);
