import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TestProjectDashboardStore } from '../stores';
import { TestProjectListModel } from '../types';
import TimeRemaining from './TimeRemaining';
import TimeElapsed from './TimeElapsed';
import TestProjectProgressBar from './TestProjectProgressBar';

type TestProjectProgressSectionProps = {
    store: TestProjectDashboardStore;
    testProject: TestProjectListModel | undefined;
};

const TestProjectProgressSection: React.FC<TestProjectProgressSectionProps> = ({ testProject }) => {
    const msRemaining =
        testProject?.processedPackageCount && testProject.avgTopicProcessingTime
            ? (testProject.packageIds.length - testProject.processedPackageCount) * testProject.avgTopicProcessingTime
            : 0;

    return (
        <div className="test-project-dashboard-progress-section">
            {testProject?.lastRunTime && <TimeElapsed datetime={testProject?.lastRunTime} />}
            <TestProjectProgressBar
                executedCount={testProject?.processedPackageCount}
                totalCount={testProject?.packageIds?.length}
            />

            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, marginBottom: 10 }}>
                <p className="bold-progress-count">{testProject?.processedPackageCount}&nbsp;</p>out of
                <p className="bold-progress-count">
                    &nbsp;{testProject?.packageIds.length}&nbsp;
                </p> packages processed
            </div>
            <TimeRemaining milliseconds={msRemaining} />
        </div>
    );
};

export default observer(TestProjectProgressSection);
