import _ from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { PackageState } from '../../common/models';
import { RulesService } from '../../rules/services';
import { TestProjectsVisualStore } from '.';
import { TestProjectService } from '../services';
import { TagModel } from '../../rules/models/TagModel';
import { TagsGroupModel } from '../../rules/models/TagsGroupModel';
import { message } from 'antd';
import {
    TestProject,
    InputBindingMetadataBase,
    AppDefInputBindingMetadata,
    AppDefInputBindingMetadataDto,
    AppDefConditionalInputBindingMetadata,
    AppDefConditionalInputBindingMetadataDto
} from '../types';
import { BindingGroup } from '../../field_bindings/types';

// Babel can't handle type exporting, so have to fall back to this
type TestProjectInputType = 'Tags' | 'Bindings' | 'ProjectFields';

export default class TestProjectWizardStore {
    @observable
    isCreationWizardVisible: boolean = false;

    @observable
    autocompleteValue: string = '';

    @observable
    searchTerm: string = '';

    @observable
    selectedTags: string[] = [];

    @observable
    selectedPackages: string[] = [];

    @observable
    currentProjectTags: TagModel[] = [];

    @observable
    currentProjectTagGroups: TagsGroupModel[] = [];

    @observable
    isLoadingRuleData: boolean = false;

    @observable
    topicInputsType: TestProjectInputType = 'Tags';

    @observable
    selectedTopicTags: string[] = [];

    @observable
    inputBindingsMetadata: InputBindingMetadataBase[] = [];

    @observable
    fieldBindingGroups: BindingGroup[] = [];

    @observable
    isLoadingFieldBindingGroups: boolean = false;

    @observable
    isLoadingInputBindingsMetadata: boolean = false;

    @observable
    selectedInputBindings: string[] = [];

    @observable
    selectedAppForBindings: string = '';

    @observable
    selectedInputGroupForBindings: string = '';

    @observable
    selectedProjectFields: string[] = [];

    @observable
    topicsSearchTerm: string = '';

    @observable
    currentTestProject: TestProject | undefined;

    @observable
    packagesEditDialogVisible: boolean = false;

    @observable
    topicsEditDialogVisible: boolean = false;

    @observable
    progressDialogVisible: boolean = false;

    @observable
    sourcePackageForBaselineSwitch: string | undefined;

    @observable
    newPackagesForBaselineSwitch: string[] = [];

    @observable
    baselinesEditDialogVisible: boolean = false;

    @computed
    get filteredPackages() {
        const packages = this.visualStore.packages.filter(
            p => p.name.toLowerCase().includes(this.searchTerm.toLowerCase()) && p.state === PackageState.Ready
        );

        if (this.selectedTags.length > 0) {
            return packages.filter(p => p.userTags != null && p.userTags.some(t => this.selectedTags.includes(t)));
        }

        return packages;
    }

    @computed
    get allPackages() {
        return this.visualStore.packages.filter(p => p.state === PackageState.Ready);
    }

    @computed
    get autocompleteOptions() {
        const packageNames = [...new Set(this.visualStore.packages.map(p => p.name))];
        return packageNames.filter(p => p.toLowerCase().includes(this.autocompleteValue.toLowerCase()));
    }

    @computed
    get packageTags() {
        return [...new Set(_.flatten(this.visualStore.packages.filter(p => p.userTags != null).map(p => p.userTags)))];
    }

    @computed
    get currentProject() {
        return this.visualStore.currentProject;
    }

    @computed
    get isLoadingPackages() {
        return this.visualStore.loadingPackages;
    }

    @computed
    get loadingPackagesProgress() {
        return this.visualStore.loadingPackagesProgress;
    }

    @computed
    get loadingPackagesErrorMessage() {
        return this.visualStore.loadingPackagesErrorMessage;
    }

    @computed
    get topics() {
        switch (this.topicInputsType) {
            case 'Tags':
                return this.selectedTopicTags;
            case 'Bindings':
                return this.selectedInputBindings;
            case 'ProjectFields':
                return this.selectedProjectFields;
            default:
                return [];
        }
    }

    constructor(
        private service: TestProjectService,
        private visualStore: TestProjectsVisualStore,
        private rulesService: RulesService
    ) {}

    @action.bound
    setTopicInputsType(type: TestProjectInputType) {
        this.topicInputsType = type;
    }

    @action.bound
    setIsCreationWizardVisible(isVisible: boolean) {
        this.isCreationWizardVisible = isVisible;
    }

    @action.bound
    async loadAllProjectPackages() {
        await this.visualStore.loadAllProjectPackages();
    }

    @action.bound
    setAutocompleteValue(value: string) {
        this.autocompleteValue = value;
    }

    @action.bound
    setSearchTerm(value: string) {
        this.searchTerm = value;
    }

    @action.bound
    resetWizard() {
        this.setAutocompleteValue('');
        this.setSearchTerm('');
        this.setSelectedPackages([]);
        this.setTopicInputsType('Tags');
        this.setSelectedTags([]);
        this.selectedTopicTags = [];
        this.selectedProjectFields = [];
        this.selectedInputBindings = [];
        this.selectedAppForBindings = '';
        this.selectedInputGroupForBindings = '';
        this.currentTestProject = undefined;
    }

    @action.bound
    setSelectedTags(tags: string[]) {
        this.selectedTags = tags;
    }

    @action.bound
    setSelectedPackages(packages: string[]) {
        this.selectedPackages = packages;
    }

    @action.bound
    setIsLoadingRuleData(isLoading: boolean) {
        this.isLoadingRuleData = isLoading;
    }

    @action.bound
    async loadProjectRuleData() {
        if (!this.currentProject) {
            return;
        }

        try {
            this.setIsLoadingRuleData(true);
            const tags = await this.rulesService.getTagsByProject(this.currentProject.id);
            const tagGroups = await this.rulesService.getTagsGroupsByProject(this.currentProject.id);

            runInAction(() => {
                this.currentProjectTags = tags || [];
                this.currentProjectTagGroups = tagGroups || [];
            });
        } catch (error) {
            message.error('Failed to load project rule data');
            console.error(error);
        } finally {
            this.setIsLoadingRuleData(true);
        }
    }

    @action.bound
    toggleTopicTag(tag: string, checked: boolean) {
        if (checked) {
            this.selectedTopicTags.push(tag);
        } else {
            this.selectedTopicTags = this.selectedTopicTags.filter(t => t !== tag);
        }
    }

    @action.bound
    toggleTopicProjectField(fieldId: string, checked: boolean) {
        if (checked) {
            this.selectedProjectFields.push(fieldId);
        } else {
            this.selectedProjectFields = this.selectedProjectFields.filter(t => t !== fieldId);
        }
    }

    @action.bound
    async loadProjectInputBindingsMetadata() {
        if (!this.currentProject) {
            return;
        }

        try {
            this.setIsLoadingInputBindingsMetadata(true);
            const metadata = await this.service.getInputBindings(this.currentProject.id);

            const inputBindingsMetadata = metadata.map(data =>
                data.applicationType === 'ApplicationDefinitionConditional'
                    ? new AppDefConditionalInputBindingMetadata(data as AppDefConditionalInputBindingMetadataDto)
                    : new AppDefInputBindingMetadata(data as AppDefInputBindingMetadataDto)
            );

            this.setInputBindingsMetadata(inputBindingsMetadata);
        } catch (error) {
            message.error('Failed to load project input bindings');
            console.error(error);
        } finally {
            this.setIsLoadingInputBindingsMetadata(false);
        }
    }

    @action.bound
    async loadProjectFieldBindingsGroups() {
        if (!this.currentProject) {
            return;
        }

        try {
            this.setIsLoadingFieldBindingGroups(true);
            const groups = await this.service.getProjectFieldGroups(this.currentProject.id);
            this.setProjectFieldBindingGroups(groups);
        } catch (error) {
            message.error('Failed to load project field bindings');
            console.error(error);
        } finally {
            this.setIsLoadingFieldBindingGroups(false);
        }
    }

    @action.bound
    setIsLoadingInputBindingsMetadata(isLoading: boolean) {
        this.isLoadingInputBindingsMetadata = isLoading;
    }

    @action.bound
    setInputBindingsMetadata(metadata: InputBindingMetadataBase[]) {
        this.inputBindingsMetadata = metadata;
    }

    @action.bound
    setIsLoadingFieldBindingGroups(isLoading: boolean) {
        this.isLoadingFieldBindingGroups = isLoading;
    }

    @action.bound
    setProjectFieldBindingGroups(groups: BindingGroup[]) {
        this.fieldBindingGroups = groups;
    }

    @action.bound
    toggleInputBinding(binding: string, checked: boolean, applicationId: string, inputGroupId?: string) {
        if (checked) {
            this.selectedInputBindings.push(binding);
            this.selectedAppForBindings = applicationId;
            this.selectedInputGroupForBindings = inputGroupId ?? '';
        } else {
            this.selectedInputBindings = this.selectedInputBindings.filter(b => b !== binding);
        }

        if (this.selectedInputBindings.length === 0) {
            this.selectedAppForBindings = '';
            this.selectedInputGroupForBindings = '';
        }
    }

    @action.bound
    setTopicsSearchTerm(term: string) {
        this.topicsSearchTerm = term;
    }

    @action.bound
    async createTestProject(name: string, defaultBaselineFuzzy?: number) {
        if (!this.currentProject) {
            return false;
        }

        try {
            const packageIds = this.selectedPackages;
            const inputsType = this.topicInputsType;
            const applicationId = inputsType === 'Bindings' ? this.selectedAppForBindings : undefined;
            const inputGroupId =
                inputsType === 'Bindings' && this.selectedInputGroupForBindings
                    ? this.selectedInputGroupForBindings
                    : undefined;
            let topicIds: string[] = [];
            switch (inputsType) {
                case 'Tags':
                    topicIds = this.selectedTopicTags;
                    break;
                case 'Bindings':
                    topicIds = this.selectedInputBindings;
                    break;
                case 'ProjectFields':
                    topicIds = this.selectedProjectFields;
                    break;
                default:
                    break;
            }

            const resp = await this.service.createTestProject(
                this.currentProject.id,
                name,
                packageIds,
                inputsType,
                topicIds,
                applicationId,
                inputGroupId,
                defaultBaselineFuzzy
            );

            if (resp.isErr()) {
                message.error('Failed to create test project');
                return false;
            }

            this.setIsCreationWizardVisible(false);
            this.resetWizard();
            message.success('Test project created successfully');
            await this.visualStore.loadTestProjects();

            return true;
        } catch (error) {
            message.error('Failed to create test project');
            console.error(error);
            return false;
        }
    }

    @action.bound
    async updateTestProject(name: string, defaultBaselineFuzzy?: number) {
        if (!this.currentProject || !this.currentTestProject) {
            return false;
        }

        try {
            const testProjectId = this.currentTestProject.id;
            const packageIds = this.selectedPackages;
            const inputsType = this.topicInputsType;
            const applicationId = inputsType === 'Bindings' ? this.selectedAppForBindings : undefined;
            const inputGroupId =
                inputsType === 'Bindings' && this.selectedInputGroupForBindings
                    ? this.selectedInputGroupForBindings
                    : undefined;
            let topicIds: string[] = [];
            switch (inputsType) {
                case 'Tags':
                    topicIds = this.selectedTopicTags;
                    break;
                case 'Bindings':
                    topicIds = this.selectedInputBindings;
                    break;
                case 'ProjectFields':
                    topicIds = this.selectedProjectFields;
                    break;
                default:
                    break;
            }
            const resp = await this.service.updateTestProject(
                this.currentProject.id,
                testProjectId,
                name,
                packageIds,
                inputsType,
                topicIds,
                applicationId,
                inputGroupId,
                defaultBaselineFuzzy
            );

            if (resp.isErr()) {
                message.error('Failed to update test project');
                return false;
            }

            this.setIsCreationWizardVisible(false);
            this.resetWizard();
            message.success('Test project updated successfully');
            await this.visualStore.loadTestProjects();

            return true;
        } catch (error) {
            message.error('Failed to updated test project');
            console.error(error);
            return false;
        }
    }

    @action.bound
    async setUpTestProject(testProjectId: string) {
        if (!this.currentProject) {
            return;
        }

        try {
            const resp = await this.service.getTestProject(this.currentProject.id, testProjectId);

            this.currentTestProject = resp;
            this.setSelectedPackages(resp.packageIds);
            this.setTopicInputsType(resp.inputsType);
            this.selectedAppForBindings = resp.applicationId || '';
            this.selectedInputGroupForBindings = resp.inputGroupId || '';

            switch (resp.inputsType) {
                case 'Tags':
                    this.selectedTopicTags = resp.topics.map(t => t.value);
                    this.selectedInputBindings = [];
                    this.selectedProjectFields = [];
                    break;
                case 'Bindings':
                    this.selectedInputBindings = resp.topics.map(t => t.value);
                    this.selectedTopicTags = [];
                    this.selectedProjectFields = [];
                    break;
                case 'ProjectFields':
                    this.selectedProjectFields = resp.topics.map(t => t.value);
                    this.selectedTopicTags = [];
                    this.selectedInputBindings = [];
                    break;
                default:
                    break;
            }
        } catch (err) {
            message.error('Failed to set up test project');
            console.error(err);
        }
    }

    @action.bound
    async updateTestProjectPackages() {
        if (!this.currentTestProject || !this.currentProject) {
            return false;
        }

        try {
            const resp = await this.service.updateTestProjectPackages(
                this.currentProject.id,
                this.currentTestProject.id,
                this.selectedPackages
            );

            if (resp.isErr()) {
                message.error('Failed to update test project packages');
                return false;
            }

            message.success('Test project packages updated successfully');
            return true;
        } catch (err) {
            message.error('Failed to update test project packages');
            console.error(err);
            return false;
        }
    }

    @action.bound
    setPackagesEditDialogVisible(isVisible: boolean) {
        this.packagesEditDialogVisible = isVisible;
    }

    @action.bound
    setTopicsEditDialogVisible(isVisible: boolean) {
        this.topicsEditDialogVisible = isVisible;
    }

    @action.bound
    async updateTestProjectTopics() {
        if (!this.currentTestProject || !this.currentProject) {
            return false;
        }

        try {
            const inputsType = this.topicInputsType;
            const appId = inputsType === 'Bindings' ? this.selectedAppForBindings : undefined;
            const inputGroupId =
                inputsType === 'Bindings' && this.selectedInputGroupForBindings
                    ? this.selectedInputGroupForBindings
                    : undefined;
            let topicIds: string[] = [];
            switch (inputsType) {
                case 'Tags':
                    topicIds = this.selectedTopicTags;
                    break;
                case 'Bindings':
                    topicIds = this.selectedInputBindings;
                    break;
                case 'ProjectFields':
                    topicIds = this.selectedProjectFields;
                    break;
                default:
                    break;
            }
            const resp = await this.service.updateTestProjectTopics(
                this.currentProject.id,
                this.currentTestProject.id,
                topicIds,
                this.topicInputsType,
                appId,
                inputGroupId
            );

            if (resp.isErr()) {
                message.error('Failed to update test project topics');
                return false;
            }

            message.success('Test project topics updated successfully');
            return true;
        } catch (err) {
            message.error('Failed to update test project topics');
            console.error(err);
            return false;
        }
    }

    @action.bound
    setSourcePackageForBaselineSwitch(packageId: string | undefined) {
        this.sourcePackageForBaselineSwitch = packageId;
    }

    @action.bound
    setNewPackagesForBaselineSwitch(packageIds: string[]) {
        this.newPackagesForBaselineSwitch = packageIds;
    }

    @action.bound
    resetBaselineSwitch() {
        this.setSourcePackageForBaselineSwitch(undefined);
        this.setNewPackagesForBaselineSwitch([]);
    }

    @action.bound
    setBaselinesEditDialogVisible(isVisible: boolean) {
        this.baselinesEditDialogVisible = isVisible;
    }

    @action.bound
    getPackagesByIds(pkgIds: string[]) {
        const foundPackages = this.allPackages.filter(p => pkgIds.includes(p.id));
        const notFoundPackages = pkgIds
            .filter(id => !foundPackages.some(p => p.id === id))
            .map(id => ({ id, name: id }));
        return [...foundPackages, ...notFoundPackages];
    }

    @action.bound
    async switchBaselinesForPackage() {
        if (
            !this.currentProject ||
            !this.currentTestProject ||
            !this.sourcePackageForBaselineSwitch ||
            !this.newPackagesForBaselineSwitch.length
        ) {
            return;
        }

        try {
            const oldPkgId = this.sourcePackageForBaselineSwitch;
            const newPkgIds = this.newPackagesForBaselineSwitch;
            const resp = await this.service.moveBaselinesToPackages(
                this.currentProject.id,
                this.currentTestProject.id,
                oldPkgId,
                newPkgIds
            );
            if (resp.isOk()) {
                message.success('Baselines updated');
                return true;
            } else {
                message.error('Failed to update baselines');
                return false;
            }
        } catch (err) {
            console.error(err);
            message.error('Failed to update baselines');
            return false;
        }
    }
}
