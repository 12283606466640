import { message } from 'antd';
import { action, computed, observable, runInAction } from 'mobx';
import { ProjectsRootVisualStore, ProjectsStore, RouterStore, UsersStore } from '../../common/stores';
import { PAGE_SIZE } from '../../common/stores/Pager';
import { SearchPackagesRequest, TestProjectChange } from '../../common/types';
import { TestProjectService } from '../services';
import { TestProjectListModel } from '../types';
import { Package } from '../../common/models';
import { ProjectsService } from '../../common/services';
import { TestProjectsNavigation } from '../routes';
import { Subject } from 'rxjs';

export default class TestProjectsVisualStore {
    @observable
    testProjects: TestProjectListModel[] = [];

    @observable
    isLoadingProjects: boolean = false;

    @observable
    searchValue: string = '';

    @observable
    loadingPackages: boolean = false;

    @observable
    loadingPackagesProgress: number = 0;

    @observable
    loadingPackagesErrorMessage?: string;

    @observable
    packages: Package[] = [];

    @observable
    testProjectChangesSubject = new Subject<TestProjectChange>();

    @observable
    isCopyingTestProject: boolean = false;

    @computed
    get currentProject() {
        return this.projectsStore.currentProject;
    }

    @computed
    get filteredProjects() {
        if (!this.searchValue || this.searchValue.trim() === '') {
            return this.testProjects;
        }

        return this.testProjects.filter(p => p.name.toLowerCase().includes(this.searchValue.toLowerCase()));
    }

    @computed
    get users() {
        return this.usersStore.users;
    }

    @computed
    get isLoadingUsers() {
        return this.usersStore.isLoading;
    }

    constructor(
        private service: TestProjectService,
        private projectsStore: ProjectsRootVisualStore,
        private projectsService: ProjectsService,
        private routerStore: RouterStore,
        private projectsBaseStore: ProjectsStore,
        private usersStore: UsersStore
    ) {
        this.projectsBaseStore.testProjectChanges.subscribe(testProjectChanges => {
            this.handleTestProjectChanges(testProjectChanges);
            this.testProjectChangesSubject.next(testProjectChanges);
        });
    }

    @action.bound
    handleTestProjectChanges(tpChanges: TestProjectChange) {
        if (tpChanges.projectId !== this.currentProject?.id) {
            return;
        }

        const testProjectToUpdate = this.testProjects.find(tp => tp.id === tpChanges.id);
        const index = this.testProjects.indexOf(testProjectToUpdate!);

        if (index < 0) {
            return;
        }

        runInAction(() => {
            const newTestProjects = [...this.testProjects];
            newTestProjects[index].isRunning = tpChanges.isRunning;
            newTestProjects[index].lastRunId = tpChanges.lastRunId;
            newTestProjects[index].lastRunFuzzy = tpChanges.lastRunFuzzy;
            newTestProjects[index].lastRunTime = tpChanges.lastRunTime;
            newTestProjects[index].lastRunError = tpChanges.lastRunError;
            newTestProjects[index].processedPackageCount = tpChanges.processedPackageCount;
            newTestProjects[index].avgTopicProcessingTime = tpChanges.avgTopicProcessingTime;

            this.testProjects = newTestProjects;
        });
    }

    @action.bound
    setIsLoadingProjects(isLoading: boolean) {
        this.isLoadingProjects = isLoading;
    }

    @action.bound
    setSearchValue(value: string) {
        this.searchValue = value;
    }

    @action.bound
    async loadTestProjects() {
        if (!this.currentProject) {
            return;
        }

        this.setIsLoadingProjects(true);
        try {
            const testProjects = await this.service.getTestProjects(this.currentProject.id);
            this.testProjects = testProjects;
        } catch (err) {
            message.error('Failed to load test projects');
            console.error(err);
        } finally {
            this.setIsLoadingProjects(false);
        }
    }

    @action.bound
    async loadAllProjectPackages() {
        if (!this.currentProject) {
            return;
        }

        try {
            runInAction(() => {
                this.loadingPackages = true;
                this.loadingPackagesProgress = 0;
            });

            let request: SearchPackagesRequest = {
                page: 0,
                pageSize: PAGE_SIZE,
                projectId: this.currentProject.id,
                allSources: true,
                uploadedBy: this.projectsStore.hasAccessToAllEntities ? undefined : this.projectsStore.currentUserId
            };
            const firstResponse = await this.projectsService.searchPackages(this.currentProject, request);

            runInAction(() => {
                this.packages = firstResponse.lines;
            });

            if (firstResponse.lines.length < firstResponse.total) {
                const pages = Math.ceil(firstResponse.total / PAGE_SIZE);
                for (let i = 1; i < pages; i++) {
                    request.page = i;
                    const response = await this.projectsService.searchPackages(this.currentProject, request);
                    runInAction(() => {
                        this.packages = this.packages.concat(response.lines);
                        this.loadingPackagesProgress = Math.floor((i / pages) * 100);
                    });
                }
            }
        } catch (err) {
            console.error(err);
            message.error(err);
            runInAction(() => {
                this.loadingPackagesErrorMessage = err;
            });
        } finally {
            runInAction(() => {
                this.loadingPackagesProgress = 100;

                setTimeout(() => {
                    this.loadingPackages = false;
                    this.loadingPackagesErrorMessage = undefined;
                }, 1500);
            });
        }
    }

    @action.bound
    goToTestProjectDashboard(id: string) {
        if (!this.currentProject) {
            return;
        }

        this.routerStore.push(
            TestProjectsNavigation.TestProjectDashboardPage.replace(':projectId', this.currentProject.id).replace(
                ':testProjectId',
                id
            )
        );
    }

    @action.bound
    getPackageById(id: string) {
        return this.packages.find(p => p.id === id);
    }

    @action.bound
    async deleteTestProject(id: string) {
        if (!this.currentProject) {
            return;
        }

        try {
            const resp = await this.service.deleteTestProject(this.currentProject.id, id);

            if (!resp.isOk()) {
                message.error(resp.error);
                console.log(resp.error);
                return;
            }

            message.success('Test project deleted');
            await this.loadTestProjects();
        } catch (err) {
            console.error(err);
            message.error('Failed to delete test project');
        }
    }

    @action.bound
    async runTestProject(testProjectId: string) {
        if (!this.currentProject) {
            return;
        }

        try {
            const resp = await this.service.runTestProject(this.currentProject.id, testProjectId);

            if (!resp.isOk()) {
                message.error(resp.error);
                console.log(resp.error);
                return;
            }

            message.success('Test project started');
            // await this.loadTestProjects();
        } catch (err) {
            console.error(err);
            message.error('Failed to start test project');
        }
    }

    @action.bound
    goToTestResults(testProjectId: string, runId: string) {
        if (!this.currentProject) {
            return;
        }

        this.routerStore.push(
            TestProjectsNavigation.TestProjectRunResultsPage.replace(':projectId', this.currentProject.id)
                .replace(':testProjectId', testProjectId)
                .replace(':runId', runId)
        );
    }

    @action.bound
    setIsCopyingTestProject(isCopying: boolean) {
        this.isCopyingTestProject = isCopying;
    }

    @action.bound
    async createCopyOfTestProject(testProjectId: string) {
        if (!this.currentProject || this.isCopyingTestProject) {
            return;
        }

        try {
            this.setIsCopyingTestProject(true);
            const resp = await this.service.createCopyOfTestProject(this.currentProject.id, testProjectId);

            if (!resp.isOk()) {
                message.error('Failed to copy test project');
                console.log(resp.error);
                return;
            }

            message.success('Test project copied');
            await this.loadTestProjects();
        } catch (err) {
            console.error(err);
            message.error('Failed to copy test project');
        } finally {
            this.setIsCopyingTestProject(false);
        }
    }

    getUserNameById(id: string) {
        return this.usersStore.getUserNameById(id);
    }
}
